<template>
  <div class="category__holder">
    <CategoryItem v-for="item in items" :item="item" :key="item.id" :closeable="closeable" @click="(e) => onClick(item.id, e)"/>
  </div>
</template>

<script lang="ts" setup>
import type Tag from '@/typings/items/tag';

const props = defineProps({
  items: {
    type: Array as PropType<Array<Tag>>,
    default: []
  },
  closeable: {
    type: Boolean as PropType<boolean>,
    default: false
  }
});

const emit = defineEmits(['clicked']);

function onClick(id: number, e: Event) {
  if (!props.closeable) return;
  emit('clicked', id);
}
</script>

<style lang="scss">
.category__holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 0.5rem;
}
</style>
